<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="change-password" slot="component">
      <p class="change-password-title">
        {{ $t("change password") }}
        <!-- <br />
        {{ $t("to") }} {{ $t("change") }} -->
      </p>
      <form @submit.prevent="changePassword()">
        <div class="form-group">
          <div v-if="formError" class="error-container form-control">
            <p class="error">{{ formError }}</p>
          </div>
          <div class="form-control">
            <label for="Old Password">{{ $t("enter old password") }}</label>
            <div class="input-container">
              <input type="password" v-model="userOldPassword" :placeholder="oldPasswordPlaceholder" />
              <span :class="localDisplayLang === 'ara' ? 'rtl-eye' : 'eye'">
                <img :src="eyeopen" @click="toggleEye($event)" alt />
              </span>
            </div>
          </div>
          <div class="form-control">
            <label for="New Password">{{ $t("enter new password") }}</label>
            <div class="input-container">
              <input type="password" v-model="userNewPassword" :placeholder="newPasswordPlaceholder" />
              <span :class="localDisplayLang === 'ara' ? 'rtl-eye' : 'eye'">
                <img :src="eyeopen" @click="toggleEye($event)" alt />
              </span>
            </div>
          </div>
        </div>
        <button
          class="button-primary update"
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
          type="submit"
          ref="update"
        >
          <span class="btn-txt-color">{{ $t("update") }}</span>
        </button>
      </form>
    </div>
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { mapGetters } from "vuex";
import snackbar from "node-snackbar";
import "../../../../node_modules/node-snackbar/dist/snackbar.css";
import SimpleCrypto from "simple-crypto-js";
import { _providerId } from "@/provider-config.js";
import { showSnackBar } from '../../../utilities';

export default {
  props: {
    closePopup: {
      type: Function,
    },
  },
  data() {
    return {
      userNewPassword: "",
      userOldPassword: "",
      newPasswordPlaceholder: "",
      oldPasswordPlaceholder: "",
      eyeopen: require("@/assets/icons/eye.svg"),
      eyeClose: require("@/assets/icons/eye-close.svg"),
      width: "32%",
      margin: "10% auto",
      radiusType: "full",
      formError: null,
      providerUniqueId: _providerId,
      localDisplayLang: null,
    };
  },
  computed: {
    ...mapGetters(["secret_key", "getRtl"]),
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    this.newPasswordPlaceholder = this.$t("enter new password");
    this.oldPasswordPlaceholder = this.$t("enter old password");

    eventBus.$on("changePasswordResponse", (response) => {
      if (response.reason) {
        // this.$refs.update.disabled = false;
        this.formError = response.reason;
        this.userNewPassword = "";
        this.userOldPassword = "";
      } else {
        if (localStorage.getItem("loginType")) {
          let LoginType = JSON.parse(localStorage.getItem("loginType"));
          if (LoginType.type == "Email") {
            let simpleCrypto = new SimpleCrypto(this.secret_key);
            LoginType.password = simpleCrypto.encrypt(this.userNewPassword);
            // save back to storage
            localStorage.setItem("loginType", JSON.stringify(LoginType));
          }
        }
        // Show the snackbar message
        // snackbar.show({
        //   text: "Password Changed Successfully!",
        //   pos: "bottom-center",
        // });

        showSnackBar(this.$t("Password Changed Successfully!"));
        this.closePopup();
      }
    });
  },
  methods: {
    changePassword() {
      if (!this.validatePassword(this.userNewPassword) || !this.validateOldPassword(this.userOldPassword)) return;
      if (this.userNewPassword === this.userOldPassword) {
        this.formError = this.$t("Password should not be same");
        this.userNewPassword = "";
        // this.userOldPassword = "";
        return;
      }
      let payload = {
        password: this.userNewPassword,
        oldpassword: this.userOldPassword,
      };
      eventBus.$emit("changePassword", payload);
      // this.$refs.update.disabled = true;
    },
  },
  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.change-password {
  padding: 50px 53px;
  &-title {
    color: #c1c1c1;
    font-size: 1.4rem;
    font-family: $font-regular;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 1.2rem;
  }
  .update {
    width: 100%;
    margin-top: 1.2rem;
  }
}

.input-container {
  position: relative;
  .rtl-change {
    color: #ffffff;
    position: absolute;
    top: 32%;
    left: 3%;
  }

  .rtl-eye {
    position: absolute;
    top: 35%;
    left: 3%;
    opacity: 0.5;
    img {
      height: 18px;
      width: 18px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 576px) {
  .change-password {
    &-title {
      font-size: 0.9rem;
      line-height: 20px;
    }
  }
}
</style>
